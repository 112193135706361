/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./style-1.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i4 from "@angular/material/button";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/platform-browser/animations";
import * as i8 from "@angular/flex-layout/extended";
import * as i9 from "@angular/flex-layout/core";
import * as i10 from "../../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i11 from "@angular/material/icon";
import * as i12 from "../../../../../../@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive";
import * as i13 from "../../../../../../@fuse/services/config.service";
import * as i14 from "@angular/router";
import * as i15 from "../../../../../../@fuse/components/navigation/navigation.component.ngfactory";
import * as i16 from "../../../../../../@fuse/components/navigation/navigation.component";
import * as i17 from "../../../../../../@fuse/components/navigation/navigation.service";
import * as i18 from "./style-1.component";
import * as i19 from "../../../../../../@fuse/components/sidebar/sidebar.service";
var styles_NavbarVerticalStyle1Component = [i0.styles];
var RenderType_NavbarVerticalStyle1Component = i1.ɵcrt({ encapsulation: 2, styles: styles_NavbarVerticalStyle1Component, data: {} });
export { RenderType_NavbarVerticalStyle1Component as RenderType_NavbarVerticalStyle1Component };
function View_NavbarVerticalStyle1Component_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "logo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["class", "logo-icon"], ["src", "../../../../../../assets/images/logos/Logo TecTrain Negativo rgb.png"], ["style", "width: 100% !important;"]], null, null, null, null, null))], null, null); }
export function View_NavbarVerticalStyle1Component_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { directive: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 15, "div", [["class", "navbar-top mat-indigo-700-bg nav-bg"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NavbarVerticalStyle1Component_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 12, "div", [["class", "buttons"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 5, "button", [["class", "toggle-sidebar-folded"], ["fxHide.lt-lg", ""], ["mat-icon-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleSidebarFolded() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(6, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.Platform, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(7, 4866048, null, 0, i8.DefaultShowHideDirective, [i1.ElementRef, i8.ShowHideStyleBuilder, i9.StyleUtils, i9.MediaMarshaller, i9.LAYOUT_CONFIG, i1.PLATFORM_ID, [2, i9.SERVER_TOKEN]], { "fxHide.lt-lg": [0, "fxHide.lt-lg"] }, null), (_l()(), i1.ɵeld(8, 0, null, 0, 2, "mat-icon", [["class", "secondary-text mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i10.View_MatIcon_0, i10.RenderType_MatIcon)), i1.ɵdid(9, 638976, null, 0, i11.MatIcon, [i1.ElementRef, i11.MatIconRegistry, [8, null]], null, null), (_l()(), i1.ɵted(-1, 0, ["menu"])), (_l()(), i1.ɵeld(11, 0, null, null, 5, "button", [["class", "toggle-sidebar-opened"], ["fxHide.gt-md", ""], ["mat-icon-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleSidebarOpened() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(12, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.Platform, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(13, 4866048, null, 0, i8.DefaultShowHideDirective, [i1.ElementRef, i8.ShowHideStyleBuilder, i9.StyleUtils, i9.MediaMarshaller, i9.LAYOUT_CONFIG, i1.PLATFORM_ID, [2, i9.SERVER_TOKEN]], { "fxHide.gt-md": [0, "fxHide.gt-md"] }, null), (_l()(), i1.ɵeld(14, 0, null, 0, 2, "mat-icon", [["class", "secondary-text mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i10.View_MatIcon_0, i10.RenderType_MatIcon)), i1.ɵdid(15, 638976, null, 0, i11.MatIcon, [i1.ElementRef, i11.MatIconRegistry, [8, null]], null, null), (_l()(), i1.ɵted(-1, 0, ["arrow_back"])), (_l()(), i1.ɵeld(17, 0, null, null, 5, "div", [["class", "navbar-scroll-container"], ["fusePerfectScrollbar", ""]], null, [["window", "resize"], ["document", "click"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 18)._updateOnResize() !== false);
        ad = (pd_0 && ad);
    } if (("document:click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 18).documentClick($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(18, 4341760, [[1, 4]], 0, i12.FusePerfectScrollbarDirective, [i1.ElementRef, i13.FuseConfigService, i5.Platform, i14.Router], { fusePerfectScrollbarOptions: [0, "fusePerfectScrollbarOptions"], enabled: [1, "enabled"] }, null), i1.ɵpod(19, { suppressScrollX: 0 }), (_l()(), i1.ɵeld(20, 0, null, null, 2, "div", [["class", "navbar-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 1, "fuse-navigation", [["layout", "vertical"]], null, null, null, i15.View_FuseNavigationComponent_0, i15.RenderType_FuseNavigationComponent)), i1.ɵdid(22, 114688, null, 0, i16.FuseNavigationComponent, [i17.FuseNavigationService], { layout: [0, "layout"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.imglogo; _ck(_v, 3, 0, currVal_0); var currVal_3 = ""; _ck(_v, 7, 0, currVal_3); _ck(_v, 9, 0); var currVal_7 = ""; _ck(_v, 13, 0, currVal_7); _ck(_v, 15, 0); var currVal_9 = _ck(_v, 19, 0, true); var currVal_10 = ""; _ck(_v, 18, 0, currVal_9, currVal_10); var currVal_11 = "vertical"; _ck(_v, 22, 0, currVal_11); }, function (_ck, _v) { var currVal_1 = (i1.ɵnov(_v, 6).disabled || null); var currVal_2 = (i1.ɵnov(_v, 6)._animationMode === "NoopAnimations"); _ck(_v, 5, 0, currVal_1, currVal_2); var currVal_4 = i1.ɵnov(_v, 9).inline; _ck(_v, 8, 0, currVal_4); var currVal_5 = (i1.ɵnov(_v, 12).disabled || null); var currVal_6 = (i1.ɵnov(_v, 12)._animationMode === "NoopAnimations"); _ck(_v, 11, 0, currVal_5, currVal_6); var currVal_8 = i1.ɵnov(_v, 15).inline; _ck(_v, 14, 0, currVal_8); }); }
export function View_NavbarVerticalStyle1Component_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "navbar-vertical-style-1", [], null, null, null, View_NavbarVerticalStyle1Component_0, RenderType_NavbarVerticalStyle1Component)), i1.ɵdid(1, 245760, null, 0, i18.NavbarVerticalStyle1Component, [i13.FuseConfigService, i17.FuseNavigationService, i19.FuseSidebarService, i14.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NavbarVerticalStyle1ComponentNgFactory = i1.ɵccf("navbar-vertical-style-1", i18.NavbarVerticalStyle1Component, View_NavbarVerticalStyle1Component_Host_0, {}, {}, []);
export { NavbarVerticalStyle1ComponentNgFactory as NavbarVerticalStyle1ComponentNgFactory };
