<div class="navbar-top mat-indigo-700-bg nav-bg">

    <div *ngIf="imglogo" class="logo">
        <img class="logo-icon" src="../../../../../../assets/images/logos/Logo TecTrain Negativo rgb.png" style="width: 100% !important;">
    </div>

    <div class="buttons">

        <button mat-icon-button class="toggle-sidebar-folded" (click)="toggleSidebarFolded()" fxHide.lt-lg>
            <mat-icon class="secondary-text">menu</mat-icon>
        </button>

        <button mat-icon-button class="toggle-sidebar-opened" (click)="toggleSidebarOpened()" fxHide.gt-md>
            <mat-icon class="secondary-text">arrow_back</mat-icon>
        </button>

    </div>

</div>


<div class="navbar-scroll-container" fusePerfectScrollbar [fusePerfectScrollbarOptions]="{suppressScrollX: true}">

    <!--

    <div class="user mat-indigo-700-bg" fxLayout="column">

        <div class="h3 username">Charlie Adams</div>
        <div class="h5 email hint-text mt-8">adams.charlie@mail.com</div>

    </div>

    -->

    <div class="navbar-content">
        <fuse-navigation layout="vertical"></fuse-navigation>
    </div>

</div>