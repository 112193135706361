import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';

import { navigation } from 'app/navigation/navigation';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss']
})

export class ToolbarComponent implements OnInit, OnDestroy {
    urlazure: string = environment.API_URL;
    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    languages: any;
    navigation: any;
    selectedLanguage: any;
    userStatusOptions: any[];
    tituloparticipante: boolean = false;
    areaadm: boolean = false;
    areainstrutor: boolean = false;

    idTurma:string;
    codTurma: string;
    instrutor: string;
    numPeriodo: string;
    numEquipe: string;
    participante: string;
    token: string = "";
    ambinenteop: string;
    tipomanual:string;
    httpOptions: any;
    titulomenutela:string="";
    tipoambiente:string="";

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {TranslateService} _translateService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private _translateService: TranslateService,
        private storage: LocalStorageService,
        private http: HttpClient
    ) {

        this.idTurma = storage.retrieve('idturma');
        this.codTurma = this.storage.retrieve('codTurma');
        this.instrutor = this.storage.retrieve('instrutor');
        this.numPeriodo = this.storage.retrieve('numPeriodo');
        this.numEquipe = this.storage.retrieve('numEquipe');
        this.participante = this.storage.retrieve('participante');

        this.token = this.storage.retrieve('token');

        if (this.token !== null) {
            this.httpOptions = {
                headers: new HttpHeaders({
                    'Authorization': 'Bearer ' + this.token['token']
                })
            };
        }

        // Set the defaults
        this.userStatusOptions = [
            {
                'title': 'Online',
                'icon': 'icon-checkbox-marked-circle',
                'color': '#4CAF50'
            },
            {
                'title': 'Away',
                'icon': 'icon-clock',
                'color': '#FFC107'
            },
            {
                'title': 'Do not Disturb',
                'icon': 'icon-minus-circle',
                'color': '#F44336'
            },
            {
                'title': 'Invisible',
                'icon': 'icon-checkbox-blank-circle-outline',
                'color': '#BDBDBD'
            },
            {
                'title': 'Offline',
                'icon': 'icon-checkbox-blank-circle-outline',
                'color': '#616161'
            }
        ];

        this.languages = [
            {
                id: 'en',
                title: 'English',
                flag: 'us'
            },
            {
                id: 'tr',
                title: 'Turkish',
                flag: 'tr'
            }
        ];

        this.navigation = navigation;

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to the config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((settings) => {
                this.horizontalNavbar = settings.layout.navbar.position === 'top';
                this.rightNavbar = settings.layout.navbar.position === 'right';
                this.hiddenNavbar = settings.layout.navbar.hidden === true;
            });

        // Set the selected language from default languages
        this.selectedLanguage = _.find(this.languages, { 'id': this._translateService.currentLang });

        if (this.storage.retrieve('tipo') === "1") {
            this.areaadm = true;
            this.areainstrutor = false;
            this.tituloparticipante = false;
            this.tipoambiente = "urlManualAdmin";
        } else if (this.storage.retrieve('tipo') === "2") {
            this.areaadm = false;
            this.areainstrutor = false;
            this.tituloparticipante = true;
        } else {
            this.areaadm = false;
            this.areainstrutor = true;
            this.tituloparticipante = false;
            this.tipoambiente = "urlManualInst";
        }

        if (this.token !== null) {
            this.carregarVersao();
        }
    }

    carregarVersao() {
        this.http.get(this.urlazure + 'api/paramsistema/amboper', this.httpOptions)
            .subscribe((result: any) => {

                let retarray = JSON.stringify(result);
                let ret = JSON.parse(retarray);

                this.ambinenteop = ret.dados[0].valorStr;

                this.carregarManual();

            },
                (error) => {
                    console.log(JSON.stringify(error));
                });
    }

    carregarManual() {
        this.http.get(this.urlazure + 'api/paramsistema/'+this.tipoambiente, this.httpOptions)
            .subscribe((result: any) => {

                let retarray = JSON.stringify(result);
                let ret = JSON.parse(retarray);

                this.tipomanual = ret.dados[0].valorStr;

                this.carregarTitulo();

            },
                (error) => {
                    console.log(JSON.stringify(error));
                });
    }

    carregarTitulo() {
        this.http.get(this.urlazure + '/api/gameparticipante/recursosetapa?etapa=0', this.httpOptions)
            .subscribe((result: any) => {

                let retarray = JSON.stringify(result);
                let ret = JSON.parse(retarray);

                console.log(ret.dados[1].titulo);

                this.titulomenutela = ret.dados[1].titulo;

            },
                (error) => {
                    console.log(JSON.stringify(error));
                });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    /**
     * Search
     *
     * @param value
     */
    search(value): void {
        // Do your search here...
        console.log(value);
    }

    /**
     * Set the language
     *
     * @param lang
     */
    setLanguage(lang): void {
        // Set the selected language for the toolbar
        this.selectedLanguage = lang;

        // Use the selected language for translations
        this._translateService.use(lang.id);
    }

    logout() {
        this.storage.store('token', null);
        document.location.href = '/apps/autenticacao/autenticar';
    }

    ajudaManual() {
        window.open(this.tipomanual, '_black');
    }
}
