var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HttpClient } from '@angular/common/http';
import { FuseUtils } from '@fuse/utils';
var ChatPanelService = /** @class */ (function () {
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    function ChatPanelService(_httpClient) {
        this._httpClient = _httpClient;
    }
    /**
     * Loader
     *
     * @returns {Promise<any> | any}
     */
    ChatPanelService.prototype.loadContacts = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            Promise.all([
                _this.getContacts(),
                _this.getUser()
            ]).then(function (_a) {
                var contacts = _a[0], user = _a[1];
                _this.contacts = contacts;
                _this.user = user;
                resolve();
            }, reject);
        });
    };
    /**
     * Get chat
     *
     * @param contactId
     * @returns {Promise<any>}
     */
    ChatPanelService.prototype.getChat = function (contactId) {
        var _this = this;
        var chatItem = this.user.chatList.find(function (item) {
            return item.contactId === contactId;
        });
        // Get the chat
        return new Promise(function (resolve, reject) {
            // If there is a chat with this user, return that.
            if (chatItem) {
                _this._httpClient.get('api/chat-panel-chats/' + chatItem.chatId)
                    .subscribe(function (chat) {
                    // Resolve the promise
                    resolve(chat);
                }, reject);
            }
            // If there is no chat with this user, create one...
            else {
                _this.createNewChat(contactId).then(function () {
                    // and then recall the getChat method
                    _this.getChat(contactId).then(function (chat) {
                        resolve(chat);
                    });
                });
            }
        });
    };
    /**
     * Create new chat
     *
     * @param contactId
     * @returns {Promise<any>}
     */
    ChatPanelService.prototype.createNewChat = function (contactId) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            // Generate a new id
            var chatId = FuseUtils.generateGUID();
            // Prepare the chat object
            var chat = {
                id: chatId,
                dialog: []
            };
            // Prepare the chat list entry
            var chatListItem = {
                chatId: chatId,
                contactId: contactId,
                lastMessageTime: '2017-02-18T10:30:18.931Z'
            };
            // Add new chat list item to the user's chat list
            _this.user.chatList.push(chatListItem);
            // Post the created chat to the server
            _this._httpClient.post('api/chat-panel-chats', __assign({}, chat))
                .subscribe(function () {
                // Post the updated user data to the server
                _this._httpClient.post('api/chat-panel-user/' + _this.user.id, _this.user)
                    .subscribe(function () {
                    // Resolve the promise
                    resolve();
                });
            }, reject);
        });
    };
    /**
     * Update the chat
     *
     * @param chatId
     * @param dialog
     * @returns {Promise<any>}
     */
    ChatPanelService.prototype.updateChat = function (chatId, dialog) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var newData = {
                id: chatId,
                dialog: dialog
            };
            _this._httpClient.post('api/chat-panel-chats/' + chatId, newData)
                .subscribe(function (updatedChat) {
                resolve(updatedChat);
            }, reject);
        });
    };
    /**
     * Get contacts
     *
     * @returns {Promise<any>}
     */
    ChatPanelService.prototype.getContacts = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this._httpClient.get('api/chat-panel-contacts')
                .subscribe(function (response) {
                resolve(response);
            }, reject);
        });
    };
    /**
     * Get user
     *
     * @returns {Promise<any>}
     */
    ChatPanelService.prototype.getUser = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this._httpClient.get('api/chat-panel-user')
                .subscribe(function (response) {
                resolve(response[0]);
            }, reject);
        });
    };
    return ChatPanelService;
}());
export { ChatPanelService };
