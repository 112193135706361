import { Component, Inject, OnDestroy, OnInit, ElementRef, Compiler } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/observable/interval';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';

import { navigation } from 'app/navigation/navigation';
import { navigationusuario } from 'app/navigation/navigationusuario';
import { locale as navigationEnglish } from 'app/navigation/i18n/en';
import { locale as navigationTurkish } from 'app/navigation/i18n/tr';

import { environment } from './../environments/environment';
import { navigationusuariogerrel } from './navigation/navigationusuariogerrel';

@Component({
    selector: 'app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    urlazure: string = environment.API_URL;
    fuseConfig: any;
    navigation: any;
    tipo: string;
    elementiniciotela: any;
    elementcenariotela: any;
    elementrelatorios: any;
    elementdecisoes: any;
    elementdecisoespessoas: any;
    elementatividades: any;
    elementenviardecisoes: any;
    iniciotela: string;
    cenariotela: string;
    relatorios: string;
    decisoes: string;
    atividades: string;
    enviardecisoes: string;
    httpOptions: any;
    token: string = "";
    tipogame: string;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {DOCUMENT} document
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {FuseSplashScreenService} _fuseSplashScreenService
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     * @param {Platform} _platform
     * @param {TranslateService} _translateService
     */
    constructor(
        @Inject(DOCUMENT) private document: any,
        private _fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseSidebarService: FuseSidebarService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _platform: Platform,
        private _storage: LocalStorageService,
        public elementRef: ElementRef,
        private http: HttpClient,
        private _compiler: Compiler
    ) {
        this.tipo = this._storage.retrieve('tipo');
        this.token = this._storage.retrieve('token');
        this.tipogame = this._storage.retrieve('tipogame');
        this._compiler.clearCache();
        /*
                this.iniciotela = this._storage.retrieve('10');
                this.cenariotela = this._storage.retrieve('20');
                this.relatorios = this._storage.retrieve('30');
                this.decisoes = this._storage.retrieve('40');
                this.atividades = this._storage.retrieve('50');
                this.enviardecisoes = this._storage.retrieve('60');
        */
        if (this.tipo === "1") {
            // Get default navigation
            this.navigation = navigation;
            this.carregarAplicacao();
        } else if (this.tipo === "2") {
            // Get default navigation
            if (this.tipogame === "gamegerloja") {
                this.navigation = navigationusuario;
            } else {
                this.navigation = navigationusuariogerrel;
            }

            this.carregarAplicacao();
        } else if (this.tipo === "3") {
            // Get default navigation
            if (this.tipogame === "gamegerloja") {
                this.navigation = navigationusuario;
            } else {
                this.navigation = navigationusuariogerrel;
            }
            
            this.carregarAplicacao();
        }

    }

    carregarAplicacao() {
        // Register the navigation to the service
        this._fuseNavigationService.register('main', this.navigation);

        // Set the main navigation as our current navigation
        this._fuseNavigationService.setCurrentNavigation('main');

        // Add languages
        this._translateService.addLangs(['pt', 'tr']);

        // Set the default language
        this._translateService.setDefaultLang('pt');

        // Set the navigation translations
        //this._fuseTranslationLoaderService.loadTranslations(navigationEnglish, navigationTurkish);

        // Use a language
        this._translateService.use('pt');

        // Add is-mobile class to the body if the platform is mobile
        if (this._platform.ANDROID || this._platform.IOS) {
            this.document.body.classList.add('is-mobile');
        }

        if (this.tipo === "2") {
            this.httpOptions = {
                headers: new HttpHeaders({
                    'Authorization': 'Bearer ' + this.token['token']
                })
            };
            setTimeout(() => {
                this.elementiniciotela = this.elementRef.nativeElement.getElementsByClassName('iniciotelasmenu');
                this.elementcenariotela = this.elementRef.nativeElement.getElementsByClassName('cenariotelamenu');
                this.elementrelatorios = this.elementRef.nativeElement.getElementsByClassName('relatoriomenu');
                this.elementdecisoes = this.elementRef.nativeElement.getElementsByClassName('decisoesmenu');
                this.elementdecisoespessoas = this.elementRef.nativeElement.getElementsByClassName('decisoespessoasmenu');
                this.elementatividades = this.elementRef.nativeElement.getElementsByClassName('atividadesmenu');
                this.elementenviardecisoes = this.elementRef.nativeElement.getElementsByClassName('enviardecisoesmenu');

                this.http.get(this.urlazure + 'api/gameparticipante/telasdisponiveis/', this.httpOptions)
                    .subscribe((result: any) => {

                        let retarray = JSON.stringify(result);
                        let ret = JSON.parse(retarray);

                        let arraymenu = ret.dados;

                        for (var i = 0; i < arraymenu.length; i++) {

                            if (arraymenu[i].codigo.toString().trim() === "10" && arraymenu[i].valor.toString().trim() === "0") {
                                this.elementiniciotela[0].className = "removermenu";
                            }

                            if (arraymenu[i].codigo.toString().trim() === "20" && arraymenu[i].valor.toString().trim() === "0") {
                                this.elementcenariotela[0].className = "removermenu";
                            }

                            if (arraymenu[i].codigo.toString().trim() === "30" && arraymenu[i].valor.toString().trim() === "0") {
                                this.elementrelatorios[0].className = "removermenu";
                            }

                            if (arraymenu[i].codigo.toString().trim() === "40" && arraymenu[i].valor.toString().trim() === "0") {
                                this.elementdecisoes[0].className = "removermenu";
                            }

                            if (arraymenu[i].codigo.toString().trim() === "41" && arraymenu[i].valor.toString().trim() === "0") {
                                this.elementdecisoespessoas[0].className = "removermenu";
                            }

                            if (arraymenu[i].codigo.toString().trim() === "50" && arraymenu[i].valor.toString().trim() === "0") {
                                this.elementatividades[0].className = "removermenu";
                            }

                            if (arraymenu[i].codigo.toString().trim() === "60" && arraymenu[i].valor.toString().trim() === "0") {
                                this.elementenviardecisoes[0].className = "removermenu";
                            }

                        }

                    },
                        (error) => {
                            console.log(JSON.stringify(error));
                        });

            }, 500);
        }

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        console.log(this.token);
        // Subscribe to config changes
        /*this._fuseConfigService.config.pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {
                this.fuseConfig = config;

                if (this.fuseConfig.layout.width === 'boxed') {
                    this.document.body.classList.add('boxed');
                }
                else {
                    this.document.body.classList.remove('boxed');
                }
            });*/
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

}
