import { ElementRef, Renderer2 } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
var NavbarComponent = /** @class */ (function () {
    /**
     * Constructor
     *
     * @param {ElementRef} _elementRef
     * @param {Renderer2} _renderer
     */
    function NavbarComponent(_elementRef, _renderer, _storage) {
        // Set the private defaults
        this._elementRef = _elementRef;
        this._renderer = _renderer;
        this._storage = _storage;
        // Private
        this._variant = 'vertical-style-2';
        this.dynamicClass = "mat-fuse-dark-700-bg";
        this.tipo = this._storage.retrieve('tipo');
        console.log("_variant:" + this.tipo);
        if (this.tipo === "1") {
            // Remove the old class name
            this._renderer.removeClass(this._elementRef.nativeElement, this.variant);
            // Store the variant value
            this._variant = 'vertical-style-1';
            // Add the new class name
            this._renderer.addClass(this._elementRef.nativeElement, this._variant);
        }
        else if (this.tipo === "2") {
            // Remove the old class name
            this._renderer.removeClass(this._elementRef.nativeElement, this.variant);
            // Store the variant value
            this._variant = 'vertical-style-2';
            // Add the new class name
            this._renderer.addClass(this._elementRef.nativeElement, this._variant);
        }
    }
    Object.defineProperty(NavbarComponent.prototype, "variant", {
        // -----------------------------------------------------------------------------------------------------
        // @ Accessors
        // -----------------------------------------------------------------------------------------------------
        /**
         * Variant
         */
        get: function () {
            if (this.tipo === "1") {
                // Store the variant value
                this._variant = 'vertical-style-1';
            }
            else if (this.tipo === "2") {
                // Store the variant value
                this._variant = 'vertical-style-2';
            }
            //console.log("VA:" + this._variant);
            return this._variant;
        },
        set: function (value) {
            // Remove the old class name
            this._renderer.removeClass(this._elementRef.nativeElement, this.variant);
            // Store the variant value
            this._variant = value;
            // Add the new class name
            this._renderer.addClass(this._elementRef.nativeElement, value);
        },
        enumerable: true,
        configurable: true
    });
    return NavbarComponent;
}());
export { NavbarComponent };
