import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id: 'applications',
        title: 'Módulo do Administrador',
        type: 'group',
        icon: 'apps',
        children: [
            {
                id: 'operacoes',
                title: 'Operações',
                type: 'collapsable',
                icon: 'dashboard',
                children: [
                    {
                        id: 'turmas',
                        title: 'Turmas',
                        type: 'item',
                        url: '/apps/cadastros/turmas'
                    }, {
                        id: 'instrutores',
                        title: 'Instrutores',
                        type: 'item',
                        url: '/apps/cadastros/instrutores'
                    }
                ]
            }, {
                id: 'cadastros',
                title: 'Cadastros',
                type: 'collapsable',
                icon: 'dashboard',
                children: [
                    {
                        id: 'cliente',
                        title: 'Clientes',
                        type: 'item',
                        url: '/apps/cadastros/cliente'
                    }, {
                        id: 'cadastroidioma',
                        title: 'Idiomas',
                        type: 'item',
                        url: '/apps/cadastros/cadastroidioma'
                    }, {
                        id: 'usuarios',
                        title: 'Usuários',
                        type: 'item',
                        url: '/apps/cadastros/usuarios'
                    }, {
                        id: 'paramsistema',
                        title: 'Parâmetros do Sistema',
                        type: 'item',
                        url: '/apps/cadastros/paramsistema'
                    }
                ]
            }, {
                id: 'configuracaogame',
                title: 'Configurações de Game',
                type: 'collapsable',
                icon: 'dashboard',
                children: [
                    {
                        id: 'versoesgame',
                        title: 'Versões do Game',
                        type: 'item',
                        url: '/apps/cadastros/versoesgame'
                    }, {
                        id: 'questionariodogame',
                        title: 'Questionários do Game',
                        type: 'item',
                        url: '/apps/cadastros/questionariodogame'
                    }, {
                        id: 'avaliacoesgame',
                        title: 'Avaliações do Game',
                        type: 'item',
                        url: '/apps/cadastros/avaliacoesgame'
                    }, {
                        id: 'recursosgame',
                        title: 'Recursos do Game',
                        type: 'item',
                        url: '/apps/cadastros/recursosgame'
                    }
                ]
            }, {
                id: 'componentes',
                title: 'Componentes de Game',
                type: 'collapsable',
                icon: 'dashboard',
                children: [
                    {
                        id: 'subquestionarios',
                        title: 'Questionários',
                        type: 'collapsable',
                        children: [
                            {
                                id: 'questionarios',
                                title: 'Questionários',
                                type: 'item',
                                url: '/apps/cadastros/questionarios'
                            }, {
                                id: 'questoes',
                                title: 'Questões',
                                type: 'item',
                                url: '/apps/cadastros/questoes'
                            }, {
                                id: 'respostasmultiplaescolha',
                                title: 'Respostas Múltipla Escolha',
                                type: 'item',
                                url: '/apps/cadastros/respostasmultiplaescolha'
                            }
                        ]
                    }, {
                        id: 'subtabelas',
                        title: 'Tabelas',
                        type: 'collapsable',
                        children: [
                            {
                                id: 'tabelacalc',
                                title: 'Tabelas de Cálculo',
                                type: 'item',
                                url: '/apps/cadastros/tabelacalc'
                            }, {
                                id: 'tabelaitem',
                                title: 'Itens de Tabela de Cálculo',
                                type: 'item',
                                url: '/apps/cadastros/tabelaitem'
                            }
                        ]
                    }, {
                        id: 'subavaliacoes',
                        title: 'Avaliações',
                        type: 'collapsable',
                        children: [
                            {
                                id: 'avaliacoes',
                                title: 'Avaliações',
                                type: 'item',
                                url: '/apps/cadastros/avaliacoes'
                            }, {
                                id: 'indicadores',
                                title: 'Indicadores',
                                type: 'item',
                                url: '/apps/cadastros/indicadores'
                            }, {
                                id: 'avaliacaoquestionario',
                                title: 'Avaliação de Questionários',
                                type: 'item',
                                url: '/apps/cadastros/avaliacaoquestionario'
                            }, {
                                id: 'avaliacaoindicador',
                                title: 'Avaliação de Indicadores',
                                type: 'item',
                                url: '/apps/cadastros/avaliacaoindicador'
                            }
                        ]
                    }, {
                        id: 'cadastrofuncionalidadesopcionais',
                        title: 'Funcionalidades Opcionais',
                        type: 'item',
                        url: '/apps/cadastros/cadastrofuncionalidadesopcionais'
                    }, {
                        id: 'recursos',
                        title: 'Recursos',
                        type: 'item',
                        url: '/apps/cadastros/recursos'
                    }
                ]
            }
        ]
    }

    /*
, {
                id: 'relatorios',
                title: 'Rrelatóios',
                type: 'collapsable',
                icon: 'dashboard',
                children: [
                    {
                        id: 'registrodecisoes',
                        title: 'Registro Decisoes',
                        type: 'item',
                        url: '/apps/relatorios/registrodecisoes'
                    }
                ]
            }
    */

    /*
    //MENU PARTICIPANTE
    , {
        id: 'applications',
        title: 'Menu',
        translate: 'NAV.APPLICATIONS',
        type: 'group',
        icon: 'apps',
        children: [
            {
                id: 'iniciotela',
                title: 'Início',
                type: 'item',
                url: '/apps/participante/iniciogame'
            }, {
                id: 'atividades',
                title: 'Atividades',
                type: 'item',
                url: '/apps/participante/atividades'
            }, {
                id: 'atividadeinicial',
                title: 'Atividade Inicial',
                type: 'item',
                url: '/apps/participante/atividadeinicial'
            }, {
                id: 'decisoes',
                title: 'Decisões',
                type: 'item',
                url: '/apps/participante/decisoes'
            }, {
                id: 'cenariotela',
                title: 'Cenário',
                type: 'item',
                url: '/apps/participante/cenario'
            }, {
                id: 'resultadocarteira',
                title: 'Resultados da Carteira',
                type: 'item',
                url: '/apps/participante/resultadocarteira'
            }, {
                id: 'dashboarddesempenho',
                title: 'Dashboard do Desempenho',
                type: 'item',
                url: '/apps/participante/dashboarddesempenho'
            }
        ]
    }*/

];
