<mat-toolbar *ngIf="areaadm" class="p-0 mat-elevation-z1 toolbar-bg">

    <div fxFill fxLayout="row" fxLayoutAlign="start center">

        <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">

            <button mat-icon-button class="navbar-toggle-button" *ngIf="!hiddenNavbar && !rightNavbar" (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
                <mat-icon class="secondary-text">menu</mat-icon>
            </button>

            <!--
            <div class="toolbar-separator" *ngIf="!hiddenNavbar && !rightNavbar" fxHide.gt-md></div>

            <div fxLayout="row" fxLayoutAlign="start center" *ngIf="horizontalNavbar">
                <div class="logo ml-16">
                    <img class="logo-icon" src="assets/images/logos/fuse.svg">
                </div>
            </div>

            <div class="px-8 px-mat-16">
                <fuse-shortcuts [navigation]="navigation"></fuse-shortcuts>
            </div>
-->

            <div *ngIf="tituloparticipante" class="px-8 px-mat-16" style="float: left; margin-left: 15px;">
                <p style="font-size: 18px;">Turma: {{codTurma}} ({{instrutor}}) / Periodo: {{numPeriodo}} / Equipe: {{numEquipe}} ({{participante}})</p>
            </div>
            <div *ngIf="areaadm" class="px-8 px-mat-16" style="float: left; margin-left: 15px;">
                <p style="font-size: 18px;">Área do Administrador</p>
            </div>
            <div *ngIf="areainstrutor" class="px-8 px-mat-16" style="float: left; margin-left: 15px;">
                <p style="font-size: 18px;">Área do Instrutor</p>
            </div>

        </div>

        <div class="" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">

            <div id="titulogame" class="px-8 px-mat-16" style="float: right;">
                <p style="font-size: 18px;">{{titulomenutela}}</p>
            </div>

            <button mat-button [matMenuTriggerFor]="userMenu" class="user-button">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <img class="avatar mr-0 mr-sm-16" src="https://tectrain.com.br/wp-content/uploads/2015/03/logo_footer.png">
                    <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
                </div>
            </button>

            <mat-menu #userMenu="matMenu" [overlapTrigger]="false">

                <!--<button mat-menu-item>
                    <mat-icon>account_circle</mat-icon>
                    <span>My Profile</span>
                </button>

                <button mat-menu-item class="">
                    <mat-icon>mail</mat-icon>
                    <span>Inbox</span>
                </button>-->

                <button mat-menu-item class="" (click)="ajudaManual()">
                    <mat-icon>class</mat-icon>
                    <span>Ajuda</span>
                </button>

                <button mat-menu-item class="" [routerLink]="'/pages/autenticacao/autenticar'">
                    <mat-icon>exit_to_app</mat-icon>
                    <span>Logout</span>
                </button>

                <button mat-menu-item class="">
                    <mat-icon>info</mat-icon>
                    <span>{{ambinenteop}}</span>
                </button>

            </mat-menu>

            <div class="toolbar-separator"></div>

            <!--

                <fuse-search-bar (input)="search($event)"></fuse-search-bar>

                <div class="toolbar-separator"></div>

                <button mat-button fxHide fxShow.gt-xs
                    class="language-button"
                    [matMenuTriggerFor]="languageMenu">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <img class="flag mr-8" [src]="'assets/images/flags/'+selectedLanguage.flag+'.png'">
                    <span class="iso text-uppercase">{{selectedLanguage.id}}</span>
                </div>
            </button>

            -->

            <mat-menu #languageMenu="matMenu" [overlapTrigger]="false">

                <button mat-menu-item *ngFor="let lang of languages" (click)="setLanguage(lang)">
                    <span fxLayout="row" fxLayoutAlign="start center">
                        <img class="flag mr-16" [src]="'assets/images/flags/'+lang.flag+'.png'">
                        <span class="iso">{{lang.title}}</span>
                    </span>
                </button>

                <button mat-menu-item [routerLink]="'/components/multi-language'">
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <span>Learn more</span>
                    </div>
                </button>

            </mat-menu>

            <div class="toolbar-separator" fxHide fxShow.gt-xs></div>

            <!--
                <button mat-icon-button fxHide.gt-md class="chat-panel-toggle-button" (click)="toggleSidebarOpen('chatPanel')"
                aria-label="Toggle chat panel">
                <mat-icon class="icon">chat</mat-icon>
            </button>
            -->


        </div>

    </div>

</mat-toolbar>

<mat-toolbar *ngIf="tituloparticipante" class="p-0 mat-elevation-z1 toolbar-part">

    <div fxFill fxLayout="row" fxLayoutAlign="start center">

        <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">

            <button mat-icon-button class="navbar-toggle-button" *ngIf="!hiddenNavbar && !rightNavbar" (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
                <mat-icon class="secondary-text">menu</mat-icon>
            </button>


            <!--
                <div class="toolbar-separator" *ngIf="!hiddenNavbar && !rightNavbar" fxHide.gt-md></div>
    
                <div fxLayout="row" fxLayoutAlign="start center" *ngIf="horizontalNavbar">
                    <div class="logo ml-16">
                        <img class="logo-icon" src="assets/images/logos/fuse.svg">
                    </div>
                </div>
    
                <div class="px-8 px-mat-16">
                    <fuse-shortcuts [navigation]="navigation"></fuse-shortcuts>
                </div>
    -->


            <div *ngIf="tituloparticipante" class="px-8 px-mat-16" style="float: left; margin-left: 15px;">
                <p id="gametitulomobile" style="font-size: 18px; margin-top: 25px !important;">{{titulomenutela}}</p>
                <!--<p id="descricaogame" style="font-size: 18px; margin-top: -23px !important; display:inline;">Turma: {{codTurma}} ({{instrutor}}) / Periodo: {{numPeriodo}} / Equipe: {{numEquipe}} ({{participante}})</p>

                <p id="titulogamedescricao" style="font-size: 18px; display:inline;">Turma: {{codTurma}} ({{instrutor}}) / Periodo: {{numPeriodo}} / Equipe: {{numEquipe}} ({{participante}})</p>-->
            </div>
            <div *ngIf="areaadm" class="px-8 px-mat-16" style="float: left; margin-left: 15px;">
                <p style="font-size: 18px;">Área do Administrador</p>
            </div>
            <div *ngIf="areainstrutor" class="px-8 px-mat-16" style="float: left; margin-left: 15px;">
                <p style="font-size: 18px;">Área do Instrutor</p>
            </div>

        </div>

        <div class="" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">

            <div id="titulogame" class="px-8 px-mat-16" style="float: right;">
                <p style="font-size: 18px;">{{titulomenutela}}</p>
            </div>

            <button mat-button [matMenuTriggerFor]="userMenu" class="user-button">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <img class="avatar mr-0 mr-sm-16" src="https://tectrain.com.br/wp-content/uploads/2015/03/logo_footer.png">
                    <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
                </div>
            </button>

            <mat-menu #userMenu="matMenu" [overlapTrigger]="false">

                <!--<button mat-menu-item>
                        <mat-icon>account_circle</mat-icon>
                        <span>My Profile</span>
                    </button>
    
                    <button mat-menu-item class="">
                        <mat-icon>mail</mat-icon>
                        <span>Inbox</span>
                    </button>-->

                <button mat-menu-item class="" [routerLink]="'/pages/autenticacao/autenticar'">
                    <mat-icon>exit_to_app</mat-icon>
                    <span>Logout</span>
                </button>

                <button mat-menu-item class="">
                    <mat-icon>info</mat-icon>
                    <span>{{ambinenteop}}</span>
                </button>

            </mat-menu>

            <div class="toolbar-separator"></div>

            <!--
    
                    <fuse-search-bar (input)="search($event)"></fuse-search-bar>
    
                    <div class="toolbar-separator"></div>
    
                    <button mat-button fxHide fxShow.gt-xs
                        class="language-button"
                        [matMenuTriggerFor]="languageMenu">
                    <div fxLayout="row" fxLayoutAlign="center center">
                        <img class="flag mr-8" [src]="'assets/images/flags/'+selectedLanguage.flag+'.png'">
                        <span class="iso text-uppercase">{{selectedLanguage.id}}</span>
                    </div>
                </button>
    
                -->

            <mat-menu #languageMenu="matMenu" [overlapTrigger]="false">

                <button mat-menu-item *ngFor="let lang of languages" (click)="setLanguage(lang)">
                    <span fxLayout="row" fxLayoutAlign="start center">
                        <img class="flag mr-16" [src]="'assets/images/flags/'+lang.flag+'.png'">
                        <span class="iso">{{lang.title}}</span>
                    </span>
                </button>

                <button mat-menu-item [routerLink]="'/components/multi-language'">
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <span>Learn more</span>
                    </div>
                </button>

            </mat-menu>

            <div class="toolbar-separator" fxHide fxShow.gt-xs></div>

            <!--
                    <button mat-icon-button fxHide.gt-md class="chat-panel-toggle-button" (click)="toggleSidebarOpen('chatPanel')"
                    aria-label="Toggle chat panel">
                    <mat-icon class="icon">chat</mat-icon>
                </button>
                -->


        </div>

    </div>

</mat-toolbar>