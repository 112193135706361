/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./navigation.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./vertical/group/group.component.ngfactory";
import * as i3 from "./vertical/group/group.component";
import * as i4 from "./vertical/collapsable/collapsable.component.ngfactory";
import * as i5 from "./vertical/collapsable/collapsable.component";
import * as i6 from "./navigation.service";
import * as i7 from "@angular/router";
import * as i8 from "./vertical/item/item.component.ngfactory";
import * as i9 from "./vertical/item/item.component";
import * as i10 from "@angular/common";
import * as i11 from "./horizontal/collapsable/collapsable.component.ngfactory";
import * as i12 from "./horizontal/collapsable/collapsable.component";
import * as i13 from "../../services/config.service";
import * as i14 from "./horizontal/item/item.component.ngfactory";
import * as i15 from "./horizontal/item/item.component";
import * as i16 from "./navigation.component";
var styles_FuseNavigationComponent = [i0.styles];
var RenderType_FuseNavigationComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_FuseNavigationComponent, data: {} });
export { RenderType_FuseNavigationComponent as RenderType_FuseNavigationComponent };
function View_FuseNavigationComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fuse-nav-vertical-group", [], [[8, "className", 0]], null, null, i2.View_FuseNavVerticalGroupComponent_0, i2.RenderType_FuseNavVerticalGroupComponent)), i1.ɵdid(1, 49152, null, 0, i3.FuseNavVerticalGroupComponent, [], { item: [0, "item"] }, null)], function (_ck, _v) { var currVal_1 = _v.parent.context.$implicit; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).classes; _ck(_v, 0, 0, currVal_0); }); }
function View_FuseNavigationComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fuse-nav-vertical-collapsable", [], [[8, "className", 0], [2, "open", null]], null, null, i4.View_FuseNavVerticalCollapsableComponent_0, i4.RenderType_FuseNavVerticalCollapsableComponent)), i1.ɵdid(1, 245760, null, 0, i5.FuseNavVerticalCollapsableComponent, [i6.FuseNavigationService, i7.Router], { item: [0, "item"] }, null)], function (_ck, _v) { var currVal_2 = _v.parent.context.$implicit; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).classes; var currVal_1 = i1.ɵnov(_v, 1).isOpen; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_FuseNavigationComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fuse-nav-vertical-item", [], [[8, "className", 0]], null, null, i8.View_FuseNavVerticalItemComponent_0, i8.RenderType_FuseNavVerticalItemComponent)), i1.ɵdid(1, 49152, null, 0, i9.FuseNavVerticalItemComponent, [], { item: [0, "item"] }, null)], function (_ck, _v) { var currVal_1 = _v.parent.context.$implicit; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).classes; _ck(_v, 0, 0, currVal_0); }); }
function View_FuseNavigationComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FuseNavigationComponent_3)), i1.ɵdid(2, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FuseNavigationComponent_4)), i1.ɵdid(4, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FuseNavigationComponent_5)), i1.ɵdid(6, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = (_v.context.$implicit.type == "group"); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_v.context.$implicit.type == "collapse"); _ck(_v, 4, 0, currVal_1); var currVal_2 = (_v.context.$implicit.type == "item"); _ck(_v, 6, 0, currVal_2); }, null); }
function View_FuseNavigationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FuseNavigationComponent_2)), i1.ɵdid(2, 278528, null, 0, i10.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.navigation; _ck(_v, 2, 0, currVal_0); }, null); }
function View_FuseNavigationComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fuse-nav-horizontal-collapsable", [], [[8, "className", 0]], [[null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("mouseenter" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).open() !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).close() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i11.View_FuseNavHorizontalCollapsableComponent_0, i11.RenderType_FuseNavHorizontalCollapsableComponent)), i1.ɵdid(1, 245760, null, 0, i12.FuseNavHorizontalCollapsableComponent, [i13.FuseConfigService], { item: [0, "item"] }, null)], function (_ck, _v) { var currVal_1 = _v.parent.context.$implicit; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).classes; _ck(_v, 0, 0, currVal_0); }); }
function View_FuseNavigationComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fuse-nav-horizontal-collapsable", [], [[8, "className", 0]], [[null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("mouseenter" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).open() !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).close() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i11.View_FuseNavHorizontalCollapsableComponent_0, i11.RenderType_FuseNavHorizontalCollapsableComponent)), i1.ɵdid(1, 245760, null, 0, i12.FuseNavHorizontalCollapsableComponent, [i13.FuseConfigService], { item: [0, "item"] }, null)], function (_ck, _v) { var currVal_1 = _v.parent.context.$implicit; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).classes; _ck(_v, 0, 0, currVal_0); }); }
function View_FuseNavigationComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fuse-nav-horizontal-item", [], [[8, "className", 0]], null, null, i14.View_FuseNavHorizontalItemComponent_0, i14.RenderType_FuseNavHorizontalItemComponent)), i1.ɵdid(1, 49152, null, 0, i15.FuseNavHorizontalItemComponent, [], { item: [0, "item"] }, null)], function (_ck, _v) { var currVal_1 = _v.parent.context.$implicit; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).classes; _ck(_v, 0, 0, currVal_0); }); }
function View_FuseNavigationComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FuseNavigationComponent_8)), i1.ɵdid(2, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FuseNavigationComponent_9)), i1.ɵdid(4, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FuseNavigationComponent_10)), i1.ɵdid(6, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = (_v.context.$implicit.type == "group"); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_v.context.$implicit.type == "collapse"); _ck(_v, 4, 0, currVal_1); var currVal_2 = (_v.context.$implicit.type == "item"); _ck(_v, 6, 0, currVal_2); }, null); }
function View_FuseNavigationComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FuseNavigationComponent_7)), i1.ɵdid(2, 278528, null, 0, i10.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.navigation; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_FuseNavigationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "nav"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i10.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "horizontal": 0, "vertical": 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FuseNavigationComponent_1)), i1.ɵdid(4, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FuseNavigationComponent_6)), i1.ɵdid(6, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "nav"; var currVal_1 = _ck(_v, 2, 0, (_co.layout === "horizontal"), (_co.layout === "vertical")); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = (_co.layout === "vertical"); _ck(_v, 4, 0, currVal_2); var currVal_3 = (_co.layout === "horizontal"); _ck(_v, 6, 0, currVal_3); }, null); }
export function View_FuseNavigationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fuse-navigation", [], null, null, null, View_FuseNavigationComponent_0, RenderType_FuseNavigationComponent)), i1.ɵdid(1, 114688, null, 0, i16.FuseNavigationComponent, [i6.FuseNavigationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FuseNavigationComponentNgFactory = i1.ɵccf("fuse-navigation", i16.FuseNavigationComponent, View_FuseNavigationComponent_Host_0, { layout: "layout", navigation: "navigation" }, {}, []);
export { FuseNavigationComponentNgFactory as FuseNavigationComponentNgFactory };
