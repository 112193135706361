import { FuseNavigation } from '@fuse/types';

export const navigationusuariogerrel: FuseNavigation[] = [
    {
        id: 'applications',
        title: 'Menu',
        type: 'group',
        icon: 'apps',
        children: [
            {
                id: 'iniciotela',
                title: 'Atividade Inicial',
                type: 'item',
                hidden: false,
                classes: 'iniciotelasmenu adicionarmenu',
                url: '/apps/participantegerrel/iniciogame'
            }, {
                id: 'cenariotela',
                title: 'Instruções',
                type: 'item',
                classes: 'cenariotelamenu adicionarmenu',
                url: '/apps/participantegerrel/cenario'
            }, {
                id: 'relatorios',
                title: 'Relatórios',
                type: 'collapsable',
                classes:'relatoriomenu adicionarmenu',                
                children: [
                    {
                        id: 'relresultado',
                        title: 'Relatórios',
                        type: 'item',
                        url: '/apps/participantegerrel/relatorios'
                    }, {
                        id: 'dashboarddesempenho',
                        title: 'Dashboard do Desempenho',
                        type: 'item',
                        url: '/apps/participantegerrel/dashboarddesempenho'
                    }
                ]
            }, {
                id: 'decisoes',
                title: 'Decisões',
                type: 'item',
                classes: 'decisoesmenu adicionarmenu',
                url: '/apps/participantegerrel/decisoes'
            }, {
                id: 'atividades',
                title: 'Atividades',
                type: 'item',
                classes: 'atividadesmenu adicionarmenu',
                url: '/apps/participantegerrel/atividades'
            }, {
                id: 'enviardecisoes',
                title: 'Enviar Decisões',
                type: 'item',
                classes: 'enviardecisoesmenu adicionarmenu',
                url: '/apps/participantegerrel/enviardecisoes'
            }
        ]
    }

];
