import { FuseNavigation } from '@fuse/types';

export const navigationusuario: FuseNavigation[] = [
    {
        id: 'applications',
        title: 'Menu',
        type: 'group',
        icon: 'apps',
        children: [
            {
                id: 'iniciotela',
                title: 'Atividade Inicial',
                type: 'item',
                hidden: false,
                classes: 'iniciotelasmenu adicionarmenu',
                url: '/apps/participante/iniciogame'
            }, {
                id: 'cenariotela',
                title: 'Instruções',
                type: 'item',
                classes: 'cenariotelamenu adicionarmenu',
                url: '/apps/participante/cenario'
            }, {
                id: 'relatorios',
                title: 'Relatórios',
                type: 'item',
                classes:'relatoriomenu adicionarmenu',                
                url: '/apps/participante/relatorios'
            }, {
                id: 'decisoes',
                title: 'Decisões de Negócios',
                type: 'item',
                classes: 'decisoesmenu adicionarmenu',
                url: '/apps/participante/decisoes'
            }, {
              id: 'decisoespessoas',
              title: 'Decisões de Pessoas',
              type: 'item',
              classes: 'decisoespessoasmenu adicionarmenu',
              url: '/apps/participante/decisoespessoas'
            }, {
                id: 'enviardecisoes',
                title: 'Enviar Decisões',
                type: 'item',
                classes: 'enviardecisoesmenu adicionarmenu',
                url: '/apps/participante/enviardecisoes'
            }
        ]
    }

];

/*, {
                id: 'atividades',
                title: 'Atividades',
                type: 'item',
                classes: 'atividadesmenu adicionarmenu',
                url: '/apps/participante/atividades'
            }, {
                        id: 'dashboarddesempenho',
                        title: 'Dashboard do Desempenho',
                        type: 'item',
                        url: '/apps/participante/dashboarddesempenho'
                    }*/