<div class="navbar-top mat-indigo-700-bg nav-bg">

    <div *ngIf="imglogo" class="logo">
        <img class="logo-icon" [src]="urlImagem" style="width: 50px !important;">
    </div>

    <div class="buttons">

        <button mat-icon-button class="toggle-sidebar-folded" (click)="toggleSidebarFolded()" fxHide.lt-lg>
            <mat-icon class="secondary-text">menu</mat-icon>
        </button>

        <button mat-icon-button class="toggle-sidebar-opened" (click)="toggleSidebarOpened()" fxHide.gt-md>
            <mat-icon class="secondary-text">arrow_back</mat-icon>
        </button>

    </div>

</div>


<div class="navbar-scroll-container" fusePerfectScrollbar [fusePerfectScrollbarOptions]="{suppressScrollX: true}">

    <!--
        <div class="user mat-fuse-dark-700-bg" fxLayout="column" style="padding: 5px 5px 5px 5px !important;">

        <img class="logo-icon" src="../../../../../../assets/images/logos/Logo TecTrain Negativo rgb.png" style="width: 200px !important;">

    </div>
    -->



    <div class="navbar-content">
        <div style="height: 50vh;">
            <fuse-navigation layout="vertical"></fuse-navigation>
        </div>

        <div style="width: 100%; float: left;">
            <hr>
            <div class="px-8 px-mat-16" style="float: left; margin-left: 15px;">
                <p style="font-size: 16px; color: #515151; font-weight: 600;">Turma: {{codTurma}}</p>
                <p style="font-size: 16px; color: #515151; font-weight: 600;">Instrutor: {{instrutor}}</p>
                <p style="font-size: 16px; color: #515151; font-weight: 600;">Periodo: {{numPeriodo}}</p>
                <p style="font-size: 16px; color: #515151; font-weight: 600;">Equipe: {{numEquipe}}</p>
                <p style="font-size: 16px; color: #515151; font-weight: 600;">Participante: {{participante}}</p>
            </div>
        </div>

        <div *ngIf="imglogo" style="margin-left: 25px !important;margin-top: 60px !important;">
            <img class="logo-icon" src="../../../../../../assets/images/logos/Logo TecTrain Negativo rgb.png" style="width: 150px !important;">
        </div>
    </div>

</div>