import { Component, ElementRef, Input, Renderer2, ViewEncapsulation } from '@angular/core';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';

@Component({
    selector: 'navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class NavbarComponent {
    // Private
    _variant: string = 'vertical-style-2';
    dynamicClass: string = "mat-fuse-dark-700-bg";
    tipo: string;
    /**
     * Constructor
     *
     * @param {ElementRef} _elementRef
     * @param {Renderer2} _renderer
     */
    constructor(
        private _elementRef: ElementRef,
        private _renderer: Renderer2,
        private _storage: LocalStorageService
    ) {
        // Set the private defaults

        this.tipo = this._storage.retrieve('tipo');
        console.log("_variant:" + this.tipo);

        if (this.tipo === "1") {
            // Remove the old class name
            this._renderer.removeClass(this._elementRef.nativeElement, this.variant);

            // Store the variant value
            this._variant = 'vertical-style-1';

            // Add the new class name
            this._renderer.addClass(this._elementRef.nativeElement, this._variant);
        } else if (this.tipo === "2") {
            // Remove the old class name
            this._renderer.removeClass(this._elementRef.nativeElement, this.variant);

            // Store the variant value
            this._variant = 'vertical-style-2';

            // Add the new class name
            this._renderer.addClass(this._elementRef.nativeElement, this._variant);
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Variant
     */
    get variant(): string {

        if (this.tipo === "1") {
            // Store the variant value
            this._variant = 'vertical-style-1';
        } else if (this.tipo === "2") {
            // Store the variant value
            this._variant = 'vertical-style-2';
        }

        //console.log("VA:" + this._variant);

        return this._variant;
    }

    @Input()
    set variant(value: string) {
        // Remove the old class name
        this._renderer.removeClass(this._elementRef.nativeElement, this.variant);

        // Store the variant value
        this._variant = value;

        // Add the new class name
        this._renderer.addClass(this._elementRef.nativeElement, value);
    }
}
